const copyToClipboard = (url) => {
  if (!navigator.clipboard) {
    return "";
  }

  return navigator.clipboard.writeText(url);
};

export const stringToBoolean = (str) => {
  if (str.toLowerCase() === "true") {
    return true;
  } else {
    return false;
  }
};

export default {
  copyToClipboard,
};

export const getSortOption = (collectionSortView, selectOptions) => {
  if (collectionSortView === "alphabetical") {
    return selectOptions.sort[3];
  } else if (collectionSortView === "newest") {
    return selectOptions.sort[1];
  } else if (collectionSortView === "order_key") {
    return selectOptions.sort[5];
  }
};
